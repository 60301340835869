import request from '@/utils/request'

// 获取文章分类接口
export function postLogin() {
  return request({
    url: '/news/get_all_cat',
    method: 'get'
  })
}

// 获取新闻列表
export function getNewsList(params) {
  return request({
    url: '/news/get_news_list',
    method: 'get',
    params
  })
}

// 获取新闻内容
export function getNewsDetail(params) {
  return request({
    url: '/news/get_news_detail',
    method: 'get',
    params
  })
}
