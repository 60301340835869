// 请求 响应拦截
import axios from 'axios'
import store from '@/store'

const service = axios.create({
  baseURL: '/srv',
  timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    if (store.state.userKey) {
      config.headers['TEST-User-Token'] = store.state.userKey
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
    const res = error.response.data
    return res
  }
)

export default service
