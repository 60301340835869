<template>
  <div :class="['card', $store.state.style]">
    <div class="news">
      <div class="msg">
        <div class="van-multi-ellipsis--l3">{{ data.title }}</div>
      </div>
      <img :src="data.imgUrl" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;
  .news {
    display: flex;
    .msg {
      width: 345px;
      font-size: 14px;
      margin-right: 5px;
    }
    img {
      width: 113px;
    }
    .imgs {
      width: 113px;
    }
  }
  .time {
    font-size: 12px;
    color: #ccc;
    span {
      margin-right: 10px;
    }
  }
}
.card.styleB {
  margin: 5px 0;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #e85346;
  box-sizing: border-box;
}
.card.styleC {
  margin: 5px 0;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
}
</style>
